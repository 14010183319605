.textStyle {
    color: #465164;
    font-family: url('../../Assets/fonts/LinotypeUnivers-430Regular.ttf');
    margin-right: 1rem;
    flex: 1;
    /* float: right; */
}

.textStyleBold {
    color: #465164;
    font-family: url("../../Assets/fonts/LinotypeUnivers-630Bold.ttf");
    margin-right: 1rem;
    flex: 1;
    float: right;
}

.groheLogo {
    width: 8rem;
    height: 5rem;
    margin-left: 0rem;
    margin-right: 2rem;
    margin-bottom: 0rem;
    margin-top: 1rem;
    float: "left";
    position: 'relative';
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .4)
}

.root {
    flex-grow: 1;
    /* background-color:#323b46;  */
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .4);
}

.navbarStyle {
    /* background-color:#323b46; */
    font-family: url("../../Assets/fonts/LinotypeUnivers-330Light.ttf");
    background-color: #ffffff;
    flex-grow: 1;
    height: 3rem;
    box-shadow: 0 0.5px 1px 0 rgba(0, 0, 0, .4);
}